<template>
    <div class="rate" :class="{ disabled: disabled }">
        <span v-if="showText" class="text">{{ curScore || score }}分</span>
        <div class="star-wrap" style="display:flex;">
            <!-- <div style="margin-right:5px" > -->
            <el-tooltip
                v-for="(i, index) in starArr"
                :key="index"
                class="item"
                effect="dark"
                :content="i.content"
                placement="bottom-start"
            >
                <i
                    :class="getClass(i.num)"
                    @mouseenter="disabled ? '' : (curScore = i.num)"
                    @mouseleave="disabled ? '' : (curScore = '')"
                    @click="disabled ? '' : setScore(i.num)"
                >
                    <i
                        v-if="disabled && i == Math.floor(score) + 1"
                        class="icon-star"
                        :style="'width:' + width"
                    />
                </i>
            </el-tooltip>
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyRate',
    props: {
        // 分数，默认0，保留一位小数
        score: {
            type: Number,
            default: 0,
        },
        // 是否只读，默认false，鼠标点击可以打分
        disabled: {
            type: Boolean,
            default: false,
        },
        // 是否显示分数，默认false
        showText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            starArr: [
                { num: 1, content: '完全不了解客户' },
                { num: 2, content: '没有可贷点' },
                { num: 3, content: '有1项以上普通可贷点，还需养几个月' },
                { num: 4, content: '有1项以上普通可贷点' },
                { num: 5, content: '有1项以上优质可贷点' },
            ],
            curScore: '',
            width: '',
        };
    },
    created: function () {
        this.getDecimal();
    },
    methods: {
        getClass(i) {
            if (this.curScore === '') {
                return i <= this.score ? 'icon-star' : 'icon-star-o';
            } else {
                return i <= this.curScore ? 'icon-star' : 'icon-star-o';
            }
        },
        getDecimal() {
            this.width =
                Number(this.score * 100 - Math.floor(this.score) * 100) + '%';
        },
        setScore(i) {
            this.$emit('update:score', i);
        },
    },
};
</script>
<style lang="less" scoped>
.rate {
    .text {
        font-size: 18px;
        color: #ff7f2c;
        font-weight: bold;
    }
    .star-wrap {
        line-height: 0;
        .icon-star-o {
            position: relative;
            width: 20px;
            height: 20px;
            line-height: 0;
            display: inline-block;
            margin-right: 12px;
            background: url('../../assets/common/star.png') 0 0 ~'/' auto 100%; // 这边是没有选中星星的图片
            .icon-star {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .icon-star {
            width: 20px;
            height: 20px;
            line-height: 0;
            display: inline-block;
            margin-right: 12px;
            background: url('../../assets/common/star-active.png') 0 0 ~'/' auto
                100%; // 这边是选中之后星星的图片
        }
        i:last-child {
            margin-right: 0px;
        }
    }
}
</style>

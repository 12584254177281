<!--
 * @FileDescription: 轮呼列表右侧的log列表
 * @Author: kfq
 * @Date: 2021/12/8
 * @LastEditors: Kfq
 * @LastEditTime: 2021/12/12
 -->
<template>
    <div class="record-list">
        <el-tabs v-model="activeName" stretch>
            <el-tab-pane v-if="isShow" :key="0" label="跟进记录" name="0">
                <div v-if="followLogs.length" class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in followLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex-center">
                                <div>{{ item.followedTime | getYMDHMS }}</div>
                                <div>({{ item.follower }})</div>
                            </div>
                            <h4>
                                <span />
                                <p style="color: #4086ec">{{ item.modifyContent }}</p>
                                <p>{{ item.followContent }}</p>
                            </h4>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <img src="@/assets/common/no-data/no-records.svg" alt />
                    <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无跟进记录</div>
                </div>
            </el-tab-pane>
            <el-tab-pane :key="1" label="流转记录" name="1">
                <div v-if="transferLogs.length > 0" class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in transferLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex-center">
                                <div>{{ item.operatedTime | getYMDHMS }}</div>
                                <div>({{ item.operator }})</div>
                            </div>
                            <h4>
                                <span />
                                {{ item.content }}
                            </h4>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <img src="@/assets/common/no-data/no-records.svg" alt />
                    <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无流转记录</div>
                </div>
            </el-tab-pane>
            <el-tab-pane v-if="isShow" :key="2" label="线索记录" name="2">
                <div v-if="clueLogs.length > 0" class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in clueLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex-center">
                                <div>{{ item.createdTime | getYMDHMS }}</div>
                                <div>({{ item.source }})</div>
                            </div>
                            <h4>
                                <span />
                                <p>信息：{{ item.info }}</p>
                                <p style="display: flex">星级：{{ item.qualityLevel }}星</p>
                                <p>意向度：{{ item.purposeLevel }}</p>
                                <p>跟进结果：{{ item.status }}</p>
                            </h4>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <img src="@/assets/common/no-data/no-records.svg" alt />
                    <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无线索记录</div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="通话记录" name="3">
                <div v-if="callLogs.length>0" :key="3" class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in callLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex-center">
                                <div>{{ item.createdTime|getYMDHMS }}</div>
                                <div>({{ item.callerName }})</div>
                            </div>
                            <div class="audio-record">
                                <div
                                    class="record-item"
                                >{{ item.callerName }}与{{ item.calledName }}的通话</div>
                                <div class="record-item">
                                    <callAudio :src="item.audioUrl" :font-size="'10px'" />
                                </div>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <img src="@/assets/common/no-data/no-records.svg" alt />
                    <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无线索记录</div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import callAudio from '../../../components/callAudio.vue';
export default {
    name: 'RecordList',
    props: {
        // 跟进记录
        followLogs: {
            type: Array,
            default: () => [],
        },
        // 流转记录
        transferLogs: {
            type: Array,
            default: () => [],
        },
        // 线索记录
        clueLogs: {
            type: Array,
            default: () => [],
        },
        // 通话记录
        callLogs: {
            type: Array,
            default: () => [],
        },
        newClient: {
            type: Number,
            default: () => 0,
        },
        type: {
            type: String,
            default: () => 'clue',
        },
    },
    data() {
        return {
            activeName: '1',
        };
    },
    computed: {
        isShow() {
            if (this.type === 'clue' && this.newClient === 1) {
                return 0;
            }
            return 1;
        },
    },
    components: {
        callAudio,
    },
};
</script>

<style lang="less" scoped>
.record-list {
    padding-left: 15px;
}
.timeblock {
    /deep/ .el-timeline {
        height: 400px;
        overflow: auto;
    }
    overflow-y: scroll;
    height: calc(100vh - 175px);
    // overflow-y: scroll;
    // 		height: 88%;
    .audio-record {
        margin-top: 10px;
        // margin: 5px 0 5px 0;
        color: #ffffff;
        width: 300px;
        height: 84px;
        background-color: #3f81e7;
        .record-item {
            margin-left: 10px;
            padding-top: 10px;
        }
    }
    ::v-deep.el-timeline-item__node--primary {
        left: 0px;
        // border: 2px solid #3f85eb;
        // background: transparent;
    }
    ::v-deep .el-timeline-item__tail {
        top: 10px;
        left: 7px;
        height: 100%;
    }
    time,
    span {
        color: #c9c9c9;
    }
    ::v-deep .el-timeline-item__timestamp {
        float: left;
    }
    h4 {
        background: rgba(246, 249, 255, 1);
        border-radius: 4px;
        color: #555555;
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
        position: relative;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: left !important;
        margin-bottom: 0 !important;
        span {
            display: inline-block;
            width: 5px;
            height: 100%;
            background: #3f85eb;
            border-radius: 10px 0 0 10px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.flex-center {
    display: flex;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #aaaaaa;
}
</style>
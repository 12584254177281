<!--
 * @FileDescription: 轮呼列表左侧的线索/客户列表
 * @Author: kfq
 * @Date: 2021/12/8
 * @LastEditors: Kfq
 * @LastEditTime: 2021/12/12
 -->
<template>
    <div class="container">
        <ul class="user-list">
            <li v-for="(item,index) in userList" :key="index" :class="item.className">
                <span>{{ item.name }}</span>
                <span>({{ item.id }})</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'UserList',
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        // 过滤客户/线索列表
        userList() {
            return this.list.map((item, index) => {
                item.className = 'user-list-item';
                if (index === 0) {
                    item.className = 'user-list-item selected';
                }
                if (index === this.list.length - 1) {
                    item.className = 'user-list-item last';
                }
                return item;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.user-list {
    text-align: center;
    .user-list-item {
        list-style: none;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 14px 20px;
        font-size: 14px;
        span {
            padding: 0 5px;
        }
    }
}
.selected {
    background: #4086ec;
    color: #fff;
}
.last {
    border-bottom: 1px solid #ccc;
}
</style>
export default {
    //  获取全部行政区划数据
    getAll() {
        let storage = sessionStorage.getItem('areas');
        if (storage) {
            let areas = JSON.parse(storage);
            return areas ? areas : null;
        } else {
            return null;
        }
    },
};

<!--
 * @FileDescription: 线索的弹窗，cv上个前端的
 * @Author: 未知
 * @Date: 未知
 * @LastEditors: Kfq
 * @LastEditTime: 2021/12/12
 -->
<template>
    <div>
        <!-- 选择跟进信息 -->
        <el-dialog
            title="线索质量反馈"
            :visible="visible && type == 1"
            width="390px"
            :before-close="closeFollow"
            center
        >
            <el-form ref="followForm" label-position="top" :model="followForm" label-width="80px">
                <el-form-item label="意向度：">
                    <el-row :gutter="10">
                        <el-col v-for="(item, index) in purposeArr" :key="index" :span="6">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="item.content"
                                placement="bottom"
                            >
                                <div
                                    :class="[
                    'purpose',
                    { select: item.number === followForm.purposeLevel }
                  ]"
                                    @click="choosePurpose(item)"
                                >{{ item.cnVal }}</div>
                            </el-tooltip>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="资质星级：">
                    <!-- <el-rate v-model="followForm.qualityLevel"></el-rate> -->
                    <div class="flex-sb">
                        <star :score.sync="followForm.qualityLevel" />
                        <div class="quality-text" @click="showEdit">可贷点识别</div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveFollow">保存</el-button>
                <el-button @click="closeFollow">取消</el-button>
            </span>
        </el-dialog>
        <!-- 选择未确认标签 -->
        <el-dialog
            title="请选择未确认标签"
            :visible="visible && type == 2"
            width="390px"
            :before-close="closeUnconfirmed"
            center
        >
            <el-row :gutter="10">
                <el-col v-for="(item, index) in unconfirmed" :key="index" :span="8">
                    <div
                        :class="['purpose', { select: item.number === unconfirmedVal }]"
                        @click="chooseUnconfirmed(item)"
                    >{{ item.cnVal }}</div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveUnconfirmed">保存</el-button>
                <el-button @click="closeUnconfirmed">取消</el-button>
            </span>
        </el-dialog>
        <!-- 无效提示弹框 -->
        <!-- <el-dialog
      title="温馨提示"
      :visible="false"
      width="390px"
      :before-close="closeUnuserDialog"
      center
    >
      <div>
        <div style="text-align:center;font-size:15px">
          线索关联客户目前在您的名下
        </div>
        <div style="text-align:center;font-size:15px;margin-top:10px">
          将线索保存为无效，客户将流入<font style="color: #FE8900;">公海</font>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toUnuseDialog">继续</el-button>
        <el-button @click="closeUnuserDialog">取消</el-button>
      </span>
        </el-dialog>-->
        <!-- 无效标签选择 -->
        <el-dialog
            title="请选择无效标签"
            :visible="showUnuserTag"
            width="390px"
            center
            :before-close="closeUnuser"
        >
            <el-row :gutter="10">
                <el-col v-for="(item, index) in unuserArr" :key="index" :span="8">
                    <div
                        :class="['purpose', { select: item.number === unuserTag }]"
                        @click="chooseUnuser(item)"
                    >{{ item.cnVal }}</div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveUnuserTag">保存</el-button>
                <el-button @click="closeUnuser">取消</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="可贷点识别（包括客户家人和朋友）"
            :visible="showQuality"
            width="500px"
            :before-close="closeQuality"
            center
        >
            <div>
                <!-- 普通 -->
                <div class="normal">普通可贷点：</div>
                <div class="content">
                    <div class="content-item">
                        <div class="content-num">1</div>
                        <div class="content-info">有银行代发工资5000以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">2</div>
                        <div class="content-info">在本地（杭州）有社保</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">3</div>
                        <div class="content-info">在本地（杭州）有公积金</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">4</div>
                        <div class="content-info">名下有车辆，年限在10年内，非运营车、公司车、货车</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">5</div>
                        <div class="content-info">在本地（杭州）执照满1年以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">6</div>
                        <div class="content-info">有保单年缴2500以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">7</div>
                        <div class="content-info">有微粒贷5000以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">8</div>
                        <div class="content-info">名下有回迁安置房</div>
                    </div>
                </div>
                <div class="excellent">优质可贷点：</div>
                <div class="content">
                    <div class="content-item">
                        <div class="content-num">1</div>
                        <div class="content-info">有公积金双边1000以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">2</div>
                        <div class="content-info">外地人社保1年，本地人社保满半年</div>
                    </div>
                    <div class="content-item" style="height:60px">
                        <div class="content-num" style="line-height:60px">3</div>
                        <div
                            class="content-info"
                            style="line-height: 29px;"
                        >名下有全款房、按揭房、公寓、厂房、排屋、别墅、商铺（非自建房、无产证的房子）</div>
                    </div>
                    <div class="content-item" style="height:60px">
                        <div class="content-num" style="line-height:60px">4</div>
                        <div
                            class="content-info"
                            style="line-height: 29px;"
                        >名下有车，裸车价20万以上，年限在3年内，非运营车、公司车、货车</div>
                    </div>
                    <div class="content-item" style="height:60px">
                        <div class="content-num" style="line-height:60px">5</div>
                        <div
                            class="content-info"
                            style="line-height: 29px;"
                        >优质工作单位：公务员、事业单位、知名大企业、世界500强、中国500强、上市公司、国企、垄断行业</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">6</div>
                        <div class="content-info">全国公司法人或者大股东，年缴税2万以上，缴税满2年</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">7</div>
                        <div class="content-info">在杭州做生意（有营业执照），季度结息28元以上</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import star from '@/components/templates/star';
export default {
    name: 'ClueLoading',
    components: {
        star,
    },
    data() {
        return {
            visible: false,
            followForm: {
                //   意向度
                purposeLevel: '',
                qualityLevel: 0,
            },
            unconfirmedVal: '',
            unuserTag: '',
            purposeArr: [],
            type: '',
            unconfirmed: [],
            unuserArr: [],
            showUnuser: false,
            showUnuserTag: false,
            showQuality: false,
            newClient: '',
        };
    },
    methods: {
        init(type, val) {
            this.type = type;
            this.visible = true;
            if (type == 3) {
                if (val) {
                    this.newClient = val;
                    this.showUnuserTag = true;
                } else {
                    this.showUnuser = true;
                }
            }
            //   意向度字典获取
            this.purposeArr = this.common.allValueNeed('loan-purpose-level');
            this.purposeArr.forEach((item) => {
                if (item.number == 0) {
                    item.content = '沟通过贷款问题，或暂无意向';
                } else if (item.number == 1) {
                    item.content = '有贷款意向，还存在较多顾虑';
                } else if (item.number == 2) {
                    item.content = '有明确贷款意向，不急需用钱';
                } else if (item.number == 3) {
                    item.content = '迫切需要贷款，急需用钱';
                }
            });
            //线索未确认标签
            this.unconfirmed = this.common.allValueNeed('clue-unconfirmed-tag');
            //   线索无效标签
            this.unuserArr = this.common.allValueNeed('clue-invalid-tag');
        },
        // 选择意向度
        choosePurpose(item) {
            console.log(item.number);
            this.followForm.purposeLevel = item.number;
        },
        saveFollow() {
            if (this.followForm.purposeLevel === '') {
                this.$message.error('请选择意向度');
            } else {
                if (!this.followForm.qualityLevel) {
                    this.$message.error('请选择星级');
                } else {
                    this.visible = false;
                    this.$emit('submitFollow', this.followForm);
                }
            }
        },
        // 关闭跟进
        closeFollow() {
            this.visible = false;
            this.followForm.purposeLevel = '';
            this.followForm.qualityLevel = 0;
        },
        // 保存为为确认
        saveUnconfirmed() {
            if (this.unconfirmedVal === '') {
                this.$message.error('请选择未确认标签');
            } else {
                this.visible = false;
                this.$emit('saveUnsure', this.unconfirmedVal);
            }
        },
        // 未确认取消
        closeUnconfirmed() {
            this.visible = false;
            this.unconfirmedVal = '';
        },

        chooseUnconfirmed(item) {
            this.unconfirmedVal = item.number;
        },
        // 跳出无效弹框
        toUnuseDialog() {
            this.visible = false;
            // this.$emit("saveUnusertag", this.unuserTag)
            // this.showUnuserTag = true;
        },
        closeUnuserDialog() {
            this.visible = false;
        },
        // 保存为无效标签
        saveUnuserTag() {
            if (this.unuserTag === '') {
                this.$message.error('无效标签不能为空');
            } else {
                this.showUnuserTag = false;
                this.$emit('saveUnusertag', this.unuserTag);
            }
        },
        // 关闭无效标签选择
        closeUnuser() {
            this.unuserTag = '';
            this.showUnuserTag = false;
            this.visible = false;
        },
        // 选择无效标签
        chooseUnuser(item) {
            this.unuserTag = item.number;
        },
        //展示可贷点
        showEdit() {
            this.visible = false;
            this.showQuality = true;
            // this.$emit('showQuality')
        },
        closeQuality() {
            this.visible = true;
            this.showQuality = false;
        },
    },
};
</script>

<style lang="less" scoped>
.flex-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.purpose {
    text-align: center;
    font-size: 14px;
    color: #aaaaaa;
    background-color: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;

    &:hover {
        color: #409eff;
    }
}
.select {
    border: 1px solid #409eff;
    color: #409eff;
    background-color: rgba(245, 249, 254, 1);
}
/deep/ .el-rate__icon {
    font-size: 30px;
}
.quality-text {
    color: #aaaaaa;
    font-size: 14px;
    &:hover {
        color: #4086ec;
        cursor: pointer;
    }
}
.normal,
.excellent {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}
.content {
    border: 1px solid #aaa;
}
.content-item {
    display: flex;
    align-items: center;
    // height: 100%;
    height: 40px;
    // border-bottom: 1px solid #aaa;
    .content-num {
        width: 50px;
        // height: 40px;
        line-height: 40px;
        height: 100%;
        text-align: center;
        border-right: 1px solid #aaa;
    }
    .content-info {
        height: 100%;
        margin-left: 10px;
        vertical-align: middle;

        // height: 40px;
        width: 100%;
        line-height: 40px;
    }
    &:not(:last-child) {
        border-bottom: 1px solid #aaa;
    }
}
</style>

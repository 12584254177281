<template>
    <div id="turns-call-list">
        <el-card>
            <div ref="main" class="main">
                <!-- 用户列表页面 -->
                <div class="turns-call-list-left">
                    <div v-if="callServices.isEnabled()" class="start">
                        <el-button v-if="isAutoCall" type="danger" @click="startCall">停止轮呼</el-button>
                        <el-button v-else type="primary" @click="startCall">开始轮呼</el-button>
                    </div>
                    <div class="userList">
                        <user-list :list="userList" />
                    </div>
                    <div class="info">
                        剩余呼叫条数：
                        <span>{{ userList.length }}条</span>
                    </div>
                </div>
                <!-- 表单页面 -->
                <div class="turns-call-list-form">
                    <turns-call-page-form
                        :id="userList.length === 0 ? 0 :userList[0].id "
                        ref="turnsCallListForm"
                        :type="type"
                        :page-type="pageType"
                        :res-data="resData"
                    />
                </div>
                <!-- 记录页面 -->
                <div class="turns-call-list-right">
                    <record-list
                        :follow-logs="followLogs"
                        :transfer-logs="transferLogs"
                        :clue-logs="clueLogs"
                        :call-logs="callLogs"
                        :new-client="resData? resData.newClient : 1"
                        :type="type"
                    />
                </div>
            </div>
            <!-- 底部按钮 -->
            <div class="footer">
                <footer-btn
                    :is-being-phone="isBeingPhone"
                    :type="type"
                    :page-type="pageType"
                    @returnPage="returnPage"
                    @saveToMyClient="saveToMyClient"
                    @saveUnconfirmedClue="saveUnconfirmedClue"
                    @saveInvalidClue="saveInvalidClue"
                    @skipToNext="skipToNext"
                    @saveClientFollow="saveClientFollow"
                    @saveFollowPutHighSeas="saveFollowPutHighSeas"
                    @grabClient="grabClient"
                    @saveAndGrab="saveAndGrab"
                    @throwInHighSeas="throwInHighSeas"
                />
            </div>
        </el-card>
        <!-- 线索弹窗 -->
        <clue-loading
            ref="clueFeedback"
            @submitFollow="submitFollow"
            @saveUnsure="saveUnsure"
            @saveUnusertag="saveUnusertag"
        />
    </div>
</template>

<script>
import UserList from '@/views/turnsCallList/components/UserList';
import TurnsCallPageForm from '@/views/turnsCallList/components/TurnsCallPageForm';
import RecordList from '@/views/turnsCallList/components/RecordList';
import FooterBtn from '@/views/turnsCallList/components/FooterBtn';
import ClueLoading from '@/views/turnsCallList/components/ClueLoading';
// 引入vuex辅助函数 mapState
import { mapState } from 'vuex';
import {
    getMyClueBatchFollow, // 获取我的线索轮呼列表
    getRealTimeClue, // 获取实时新线索轮呼列表
    getPublicClueRandomList, // 获取公共池线索随机轮呼列表
    getMyClueInfo, // 获取我的轮呼线索信息
    getPublicClueInfo, // 获取公共池轮呼线索信息
    clue_followed_log, //保存线索跟进记录
    addClueExpire, // 延长线索过期时间
} from '@/api/my_clue';
import {
    getMyClientCallListApi, // 获取我的客户轮呼列表
    getHighSeasClientStochasticCallListApi, // 获取公海客户随机轮呼列表
    getMyClientInfo, // 获取我的轮呼客户信息
    getMyHighSeasClientInfo, // 获取公海轮呼客户信息
    client_followed_log1, //  保存客户跟进记录
    client_public, //丢入公海
} from '@/api/my_client';
import {
    client_owner, ////抓取公海客户
} from '@/api/sea_client';
export default {
    inject: ['callClientChange'], // 这个东西调用后会拨打电话，从layout传过来的方法

    props: {
        pageType: {
            type: String,
            default: () => '',
        },
        type: {
            type: String,
            default: () => '',
        },
        previousPath: {
            type: String,
            default: () => '',
        },
        size: {
            type: String,
            default: () => '',
        },
    },

    data() {
        return {
            // 轮呼列表
            userList: [
                { id: '', name: '' }, // 初始化的数据，在可选链还在beta时，你最好留着他
            ],
            // 窗口高度
            windowinnerHeight: 0,
            // 节流定时器
            timer: null,
            // 跟进记录
            followLogs: [],
            // 流转记录
            transferLogs: [],
            // 线索记录
            clueLogs: [],
            // 通话记录
            callLogs: [],
            // 映射表单组件的formData
            formData: null,
            //  网络请求回来的用户信息数据
            resData: null,
            // 是否自动轮播
            isAutoCall: false,
            isError: 0,
            /*
          这个主要用于做提示信息判断的
          因为列表全部处理完返回上一页的提醒和一进到当前页面列表就为空，返回到上一页的消息提示是不一样的
          所以就判断当前是否翻过页，翻过页就说明不是一进到当前页面列表就为空
        */
            isListNull: false,
            // 线索倒计时的定时器
            clueCountdown: null,
            // 用于判断是否在抓取客户后保存
            isSaveClient: 0,
            isPutHighSeas: 0,
        };
    },

    methods: {
        // 验证表单方法
        checkForm() {
            let data;
            this.$refs.turnsCallListForm.$refs.formData.validate((valid) => {
                data = valid;
            });
            return data;
        },
        // 返回上一页
        returnPage() {
            setTimeout(() => {
                this.warn(
                    this.isListNull ? '本次批量呼叫已结束' : '当前暂无更多'
                );
            }, 200);
            this.$router.push(this.previousPath);
        },
        // 保存至我的客户
        saveToMyClient() {
            if (!this.checkForm()) return;
            this.$refs.clueFeedback.init(1);
            console.log('保存至我的客户');
        },
        // 保存为未确认线索
        saveUnconfirmedClue() {
            this.$refs.clueFeedback.init(2);
            console.log('保存为未确认线索');
        },
        // 保存为无效线索
        saveInvalidClue() {
            this.$refs.clueFeedback.init(3, 3);
            console.log('保存为无效线索');
        },
        submitFollow(data) {
            this.$refs.turnsCallListForm.formData.purposeLevel =
                data.purposeLevel;
            this.$refs.turnsCallListForm.formData.qualityLevel =
                data.qualityLevel;
            this.saveClue(1);
        },
        saveUnsure(data) {
            this.$refs.turnsCallListForm.formData.tag = data;
            this.saveClue(2);
        },
        saveUnusertag(data) {
            console.log(data);
            this.$refs.turnsCallListForm.formData.tag = data;
            this.saveClue(3);
        },
        // 保存线索
        saveClue(type) {
            this.$refs.turnsCallListForm.formData.clueFollowResult = type;
            this.$refs.turnsCallListForm.formData.clueId = this.userList[0].id;
            clue_followed_log(this.$refs.turnsCallListForm.formData).then(
                (res) => {
                    if (res.code !== '0') this.warn(res.message);
                    this.skipToNext();
                }
            );
        },
        // 跳转下一个
        skipToNext() {
            this.userList.shift();
            this.isListNull = true;
            if (this.userList.length !== 0) {
                setTimeout(() => {
                    this.warn('已跳转下一个客户');
                }, 100);
            }
            console.log('跳转下一个');
        },
        // 保存客户跟进
        saveClientFollow() {
            if (!this.checkForm()) return;
            this.isPutHighSeas = 0;
            // 调用保存客户跟进api
            this.saveClientFollowApi();
        },
        // 保存跟进并丢入公海
        saveFollowPutHighSeas() {
            this.isPutHighSeas = 1;
            if (!this.checkForm()) return;
            if (this.pageType === 1) {
                // 调用抓取客户api
                this.grabClientApi();
            }
            // 调用保存客户跟进api
            this.saveClientFollowApi();
        },
        // 抓取客户
        grabClient() {
            // 调用抓取客户api
            this.isSaveClient = 0;
            this.grabClientApi();
            this.skipToNext();
        },
        // 抓取并保存跟进
        saveAndGrab() {
            if (!this.checkForm()) return;
            this.isSaveClient = 1;
            // 调用抓取客户api
            this.grabClientApi();
        },
        // 丢入公海
        throwInHighSeas() {
            if (this.pageType === '0') {
                // 调用丢入公海api
                this.saveHighSeasApi();
            }
            this.skipToNext();
            this.warn('客户已丢入公海');
        },
        // 开始轮呼
        startCall() {
            // 取反自动拨打
            this.isAutoCall = !this.isAutoCall;
            if (this.isAutoCall && !this.isBeingPhone) this.call(); //调用拨打电话
        },
        // 拨打电话
        call() {
            if (this.type === 'clue' && this.pageType === '0')
                this.addClueExpire();
            this.$store.commit('callJudge', true); //这条东西我属实是不知道干什么的，反正写上就对了
            this.callClientChange();
        },
        // 提醒通知弹窗
        warn(text) {
            this.$nextTick(() => {
                this.$message({
                    message: text,
                    type: 'success',
                });
            });
        },
        // 保存客户跟进api
        saveClientFollowApi() {
            this.$refs.turnsCallListForm.formData.clientId =
                this.userList[0].id;
            client_followed_log1(this.$refs.turnsCallListForm.formData).then(
                (res) => {
                    if (res.code !== '0') {
                        this.$message.error(res.message);
                        this.isError = false;
                        if (res.code === '') return this.skipToNext();
                    } else {
                        if (this.isPutHighSeas === 1) {
                            // 调用丢入公海api
                            this.saveHighSeasApi();
                            this.warn('客户跟进信息保存成功，并丢入公海');
                            this.isPutHighSeas = 0;
                        } else if (this.isPutHighSeas === 0) {
                            this.warn('客户跟进信息保存成功');
                        }
                        this.isError = true;
                        this.skipToNext();
                    }
                }
            );
            console.log('保存客户跟进');
            return this.isError;
        },
        // 丢入公海api
        saveHighSeasApi() {
            client_public({ clientIds: [this.userList[0].id] }) // 丢公海
                .then((res) => {
                    console.log(res);
                });
            console.log('保存跟进并丢入公海');
        },
        // 抓取客户api
        grabClientApi() {
            client_owner({
                clientId: this.userList[0].id,
            }).then((res) => {
                if (res.code !== '0') return this.warn(res.message);
                if (this.isSaveClient) {
                    this.saveClientFollowApi();
                    this.warn('客户跟进信息保存成功');
                }
            });
            console.log('抓取客户');
        },
        // 获取线索列表
        getClueList() {
            getMyClueBatchFollow().then((res) => {
                console.log(res);
                if (res.code !== '0') return console.log('获取线索列表错误');
                this.userList = res.data.list;
            });
        },
        // 获取实时新线索轮呼列表
        getRealTimeClueList() {
            getRealTimeClue().then((res) => {
                if (res.code !== '0')
                    return console.log('获取实时新线索轮呼列表错误');
                this.userList = res.data.list;
            });
        },
        // 获取公共池线索随机轮呼列表
        getPublicClueRandomList() {
            getPublicClueRandomList(this.size).then((res) => {
                if (res.code !== '0')
                    return console.log('获取公共池线索随机轮呼列表错误');
                this.userList = res.data.list;
            });
        },
        // 获取我的客户轮呼列表
        getMyClientCallList() {
            getMyClientCallListApi().then((res) => {
                if (res.code !== '0')
                    return console.log('获取我的客户轮呼列表错误');
                this.userList = res.data.list;
                // console.log(res)
            });
        },
        // 获取公海客户随机轮呼列表
        getHighSeasClientStochasticCallList() {
            getHighSeasClientStochasticCallListApi(this.size).then((res) => {
                if (res.code !== '0')
                    return console.log('获取公海客户随机轮呼列表错误');
                this.userList = res.data.list;
            });
        },
        // 获取我的轮呼线索信息
        getMyClueInfo() {
            getMyClueInfo(this.userList[0].id).then((res) => {
                if (res.code !== '0') {
                    this.$nextTick(() => {
                        this.warn(res.message);
                        this.skipToNext();
                        return;
                    });
                }
                // 更新用户信息
                this.uploadUserInfo(res.data);
                this.startUpClueCountdown(res.data.expiredTime);
            });
        },
        // 获取公共池轮呼线索信息
        getPublicClueInfo() {
            getPublicClueInfo(this.userList[0].id).then((res) => {
                if (res.code !== '0') return this.skipToNext();
                // 更新用户信息
                this.uploadUserInfo(res.data);
            });
        },
        // 获取我的轮呼客户信息
        getMyClientInfo() {
            getMyClientInfo(this.userList[0].id).then((res) => {
                if (res.code !== '0') {
                    this.$nextTick(() => {
                        this.warn(res.message);
                        this.skipToNext();
                        return;
                    });
                }
                // 更新用户信息
                this.uploadUserInfo(res.data);
            });
        },
        // 获取公海轮呼客户信息
        getMyHighSeasClientInfo() {
            getMyHighSeasClientInfo(this.userList[0].id).then((res) => {
                if (res.code !== '0') return this.skipToNext();
                // 更新用户信息
                this.uploadUserInfo(res.data);
            });
        },
        // 侦听页面高度变化回调函数
        eventListening() {
            this.windowinnerHeight = window.innerHeight;
        },
        // 获取轮呼列表
        getCallList() {
            this.type === 'clue'
                ? this.fromClue('list') // 线索而来
                : this.fromClient('list'); // 客户而来
        },
        // 获取当前轮呼对象的信息
        getUserInfo() {
            this.type === 'clue'
                ? this.fromClue('info') // 线索而来
                : this.fromClient('info'); // 客户而来
        },
        // 来自于线索
        fromClue(fromType) {
            if (this.pageType === '0') {
                fromType === 'list'
                    ? this.getClueList() // 获取我的线索列表
                    : this.getMyClueInfo(); // 获取我的轮呼线索信息
            }
            if (this.pageType === '1') {
                fromType === 'list'
                    ? this.getPublicClueRandomList() // 获取公共池线索随机轮呼列表
                    : this.getPublicClueInfo(); // 获取公共池轮呼线索信息
            } else if (this.pageType === '2') {
                fromType === 'list'
                    ? this.getRealTimeClueList() // 获取实时新线索轮呼列表
                    : this.getMyClueInfo(); // 获取我的轮呼线索信息
            }
        },
        // 来自于客户
        fromClient(fromType) {
            if (this.pageType === '0') {
                fromType === 'list'
                    ? this.getMyClientCallList() // 获取我的客户列表
                    : this.getMyClientInfo(); // 获取我的轮呼客户信息
            }
            if (this.pageType === '1') {
                fromType === 'list'
                    ? this.getHighSeasClientStochasticCallList() // 获取公共池客户随机轮呼列表
                    : this.getMyHighSeasClientInfo(); // 获取公海轮呼客户信息
            }
        },
        // 更新记录
        uploadRecord(data) {
            console.log(data.operateLogs);
            console.log();
            this.clueLogs = data.clueLogs;
            this.followLogs = data.followLogs;
            this.transferLogs = data.transferLogs
                ? data.transferLogs
                : data.operateLogs;
            this.callLogs = data.callLogs;
        },
        // 更新用户信息
        uploadUserInfo(data) {
            // 保存网络请求回来的用户信息数据
            this.resData = data;
            let clientInfo;
            if (this.type === 'clue') {
                if (data.client.client) {
                    clientInfo = data.client.client;
                } else {
                    clientInfo = data.client;
                }
                // 将客户信息直接保存到表单组件
                clientInfo.mobileNumber = data.mobileNumber;
                clientInfo.createdTime = data.expiredTime;
                // 保存四个记录
                if (data.newClient) {
                    this.uploadRecord(data);
                } else {
                    this.uploadRecord(clientInfo);
                }
            } else {
                clientInfo = data;
                this.uploadRecord(clientInfo);
            }

            this.$refs.turnsCallListForm.formData = clientInfo;
            // 映射表单组件formData
            this.formData = this.$refs.turnsCallListForm.formData;
            // 生成通话配置
            function guid() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                    /[xy]/g,
                    function (c) {
                        let r = (Math.random() * 16) | 0,
                            v = c == 'x' ? r : (r & 0x3) | 0x8;
                        return v.toString(16);
                    }
                );
            }
            let callParams = {
                phoneNumber: this.common.hidePhoneNumber(data.mobileNumber, 1),
                callUUID: guid(),
                calledName: data.name,
                token: sessionStorage.getItem('sipToken'),
            };

            if (this.type === 'clue') {
                if (this.resData.newClient === 1) {
                    callParams.clueId = this.userList[0].id;
                } else {
                    callParams.clueId = this.userList[0].id;
                    callParams.clientId = this.resData.client.id;
                }
            } else {
                callParams.clientId = this.userList[0].id;
            }
            console.log(callParams);
            this.$store.commit('callSatausChange', callParams);
            /*
          这个也是调用拨打电话，你肯定能看懂（我还是说一下吧）
          就是用户变更后，看自动拨打电话打没打开，
          打开就自动拨打电话
        */
            if (this.isAutoCall) this.call();
        },
        // 启动我的线索过期
        startUpClueCountdown(endTime) {
            window.clearInterval(this.clueCountdown);
            const now = Date.parse(new Date()) / 1000;
            let msec = endTime - now;
            this.clueCountdown = setInterval(() => {
                if (msec <= 0) {
                    window.clearInterval(this.clueCountdown);
                    this.warn('当前线索以超时，自动跳转至下一条线索');
                    this.skipToNext();
                }
                msec = msec - 1;
                // console.log(`该线索剩余${msec}秒过期`)
            }, 1000);
        },
        // 延长线索时间
        addClueExpire() {
            addClueExpire(this.userList[0].id).then((res) => {
                if (res.code !== '0') return console.log(res.message);
                console.log(res);
                this.startUpClueCountdown(res.data.expireTime);
            });
        },
    },

    computed: {
        // 解构vuex状态
        ...mapState(['isBeingPhone']),
    },

    watch: {
        // 当页面高度发生变化，改变main的高度
        windowinnerHeight(val) {
            clearTimeout(this.timer);
            // 节流
            this.timer = setTimeout(() => {
                this.$refs.main.style.height = val - 215 + 'px';
            }, 200);
        },
        // 侦听轮呼列表的变化
        userList: {
            handler(newArr) {
                // 当轮呼列表为空时，自动返回上一页
                if (newArr === null || newArr.length === 0)
                    return this.returnPage();
                // 获取当前轮呼对象的信息
                this.getUserInfo();
                console.log('列表发生变化');
            },
            // immediate: true
        },
    },

    created() {
        // 动态控制main高度
        this.windowinnerHeight = window.innerHeight;
        window.addEventListener('resize', this.eventListening);
        // 获取轮呼列表
        this.getCallList();
    },

    beforeDestroy() {
        // 销毁前移除页面高度侦听,防止内存泄漏
        window.removeEventListener('resize', this.eventListening);
        // 销毁线索过期定时器
        window.clearInterval(this.clueCountdown);
    },

    // 组件挂载
    components: {
        UserList,
        TurnsCallPageForm,
        RecordList,
        FooterBtn,
        ClueLoading,
    },
};
</script>

<style lang="less" scoped>
#turns-call-list {
    flex-direction: column;
    padding: 10px;
}
.main {
    display: flex;
    width: 100%;
    min-height: 558px;
    .turns-call-list-left {
        flex: 20;
        flex-direction: column;
        display: flex;
        height: 100%;
        .start {
            height: 52px;
            text-align: center;
            padding-bottom: 20px;
        }
        .userList {
            height: 515px;
            overflow: auto;
            padding: 0 4px;
        }
        .info {
            height: 50px;
            padding-top: 20px;
            font-size: 16px;
            text-align: center;
            span {
                color: red;
            }
        }
    }
    .turns-call-list-form {
        flex: 80;
    }
    .turns-call-list-right {
        width: 320px;
        margin-top: 150px;
    }
}
.footer {
    height: 92px;
}
</style>
<!--
 * @FileDescription: 轮呼下面的按钮
 * @Author: kfq
 * @Date: 2021/12/8
 * @LastEditors: Kfq
 * @LastEditTime: 2021/12/12
 -->
<template>
    <div class="main">
        <div class="return-page" :span="6" @click="$emit('returnPage')">
            <img src="@/assets/common/return-to-previous-page.png" alt />
            返回列表
        </div>

        <div class="primarys">
            <el-row>
                <el-col :span="6">
                    <el-button
                        type="primary"
                        :disabled="isBeingPhone"
                        @click="btnOne"
                    >{{ btnOneText }}</el-button>
                </el-col>
                <el-col :span="6">
                    <el-button
                        type="primary"
                        :plain=" btnTwo === '保存为未确认线索' "
                        :disabled="isBeingPhone"
                        @click="btnTwo"
                    >{{ btnTwoText }}</el-button>
                </el-col>
                <el-col :span="6">
                    <el-button
                        type="primary"
                        :plain=" btnThree === '保存为无效线索' "
                        :disabled="isBeingPhone"
                        @click="btnThree"
                    >{{ btnThreeText }}</el-button>
                </el-col>
                <el-col :span="6">
                    <el-button
                        v-if="type === 'client'"
                        type="danger"
                        :disabled="isBeingPhone"
                        @click="btnFour"
                    >丢入公海</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterBtn',
    props: {
        isBeingPhone: {
            type: Boolean,
            default: () => false,
        },
        type: {
            type: String,
            default: () => 'clue',
        },
        pageType: {
            type: String,
            default: () => '0',
        },
    },
    methods: {
        /*
        下面这四个按钮事件命名属实是没办法而为
        一个按钮负责两三个事件。。。
        用了具体的名字估计。。。。。。。。

        你只要记得这四个对应的是下面四个按钮就对了
        ps：不包括返回上一页
      */
        // 按钮一
        btnOne() {
            if (this.type === 'clue') {
                // 保存至我的客户
                this.$emit('saveToMyClient');
            } else {
                return this.pageType === '0'
                    ? this.$emit('skipToNext') //  跳转下一个
                    : this.$emit('grabClient'); //// 抓取客户
            }
        },
        // 按钮二
        btnTwo() {
            if (this.type === 'clue') {
                //  保存为未确认线索
                this.$emit('saveUnconfirmedClue');
            } else {
                return this.pageType === '0'
                    ? this.$emit('saveClientFollow') // 保存客户跟进
                    : this.$emit('saveAndGrab'); //抓取并保存跟进
            }
        },
        // 按钮三
        btnThree() {
            if (this.type === 'clue') {
                //  保存为无效线索
                this.$emit('saveInvalidClue');
            } else {
                this.$emit('saveFollowPutHighSeas');
            }
        },
        // 按钮四
        btnFour() {
            // 丢入公海
            this.$emit('throwInHighSeas');
        },
    },
    computed: {
        btnOneText() {
            if (this.type === 'clue') {
                return '保存至我的客户';
            } else {
                return this.pageType === '0' ? '跳转下一个' : '抓取客户';
            }
        },
        btnTwoText() {
            if (this.type === 'clue') {
                return '保存为未确认线索';
            } else {
                return this.pageType === '0'
                    ? '保存客户跟进'
                    : '抓取并保存跟进';
            }
        },
        btnThreeText() {
            if (this.type === 'clue') {
                return '保存为无效线索';
            } else {
                return '保存跟进并丢入公海';
            }
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    position: relative;
    padding-top: 50px;
}

.return-page {
    width: 95px;
    height: 24px;
    color: #4086ec;
    cursor: pointer;
    // 禁止选中
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currentlynot supported by any browser */
    img {
        vertical-align: middle;
    }
}

.primarys {
    width: 800px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .el-col {
        text-align: center;
    }
}
</style>
<!--
 * @FileDescription: 轮呼列表的表单
 * @Author: kfq
 * @Date: 2021/12/8
 * @LastEditors: Kfq
 * @LastEditTime: 2021/12/12
 -->
<template>
    <div class="container">
        <el-form ref="formData" :model="formData" :rules="rules">
            <!-- 手机号/跟进时间  -->
            <el-row class="tip">
                <el-col :span="8">手机号：{{ common.hidePhoneNumber( formData.mobileNumber ) }}</el-col>
                <el-col :span="8">最后跟进时间：{{ lastFollowedTime }}</el-col>
                <el-col :span="8">
                    <div
                        v-if="wechatServiceEnabled&&type==='client'"
                        class="addWxBtn"
                        @click="openAddWxDialog"
                    >
                        <img src="../../../assets/common/add-wx-client.png" />
                    </div>
                </el-col>
            </el-row>

            <!-- 基本信息title -->
            <div class="title-font">
                <span>基本信息</span>
                <el-tooltip class="item" effect="dark" content="同步线索信息" placement="right">
                    <img
                        v-if=" type === 'clue' "
                        class="base-info"
                        src="@/assets/common/synchroData.svg"
                        @click="syncInfo('info')"
                    />
                </el-tooltip>
            </div>

            <!-- 基本信息表单 -->
            <el-row class="basic-info-form">
                <el-col :span="5">
                    <el-form-item label="姓名：" prop="name">
                        <el-input
                            v-model="formData.name"
                            class="name-input"
                            size="mini"
                            placeholder="请输入"
                        />
                    </el-form-item>
                </el-col>

                <el-col :span="7">
                    <el-form-item label="性别：" prop="sex">
                        <el-radio-group v-model="formData.sex" size="mini">
                            <el-radio-button
                                v-for="item in sexArr"
                                :key="item.number"
                                class="sex-input"
                                :label="item.number"
                            >{{ item.cnVal }}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>

                <el-col :span="6">
                    <el-form-item label="年龄：" prop="age">
                        <el-input
                            v-model.number="formData.age"
                            class="age-input"
                            size="mini"
                            placeholder="请输入"
                            oninput="value=value.replace(/[^\d]/g,'')"
                        >
                            <template slot="append">岁</template>
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="6">
                    <el-form-item label="居住地:" prop="residence">
                        <el-cascader
                            v-model="formData.residence"
                            class="residence-input"
                            size="mini"
                            :options="areasOption"
                            :props="{expandTrigger: 'click', label: 'name', value: 'name'}"
                        />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 贷款需求title -->
            <div class="title-font">
                <span>贷款需求</span>
                <el-tooltip class="item" effect="dark" content="同步线索信息" placement="right">
                    <img
                        v-if=" type === 'clue' "
                        class="base-info"
                        src="@/assets/common/synchroData.svg"
                        @click="syncInfo('need')"
                    />
                </el-tooltip>
            </div>

            <!-- 贷款需求表单 -->
            <el-row class="loan-demand-form">
                <el-col :span="8">
                    <el-form-item label="资金需求：" prop="fundNeeds">
                        <el-input
                            v-model="formData.fundNeeds"
                            class="loan-demand-form-fundNeeds"
                            size="mini"
                            oninput="value=value.replace(/[^\d]/g,'')"
                            placeholder="请输入"
                        >
                            <template slot="append">万</template>
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="16">
                    <el-form-item label="意向度：" prop="purposeLevel">
                        <el-radio-group v-model="formData.purposeLevel" size="mini">
                            <el-radio-button
                                v-for="item in purposeLevel"
                                :key="item.number"
                                class="sex-input"
                                :label="item.number"
                            >{{ item.cnVal }}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 资质title -->
            <div class="title-font">资质</div>

            <!-- 资质表单 -->
            <div class="qualification-form">
                <!-- 车 -->
                <el-row>
                    <el-col :span="4" class="switch-area">
                        <el-switch
                            v-model="formData.vehicle.has"
                            active-text="车辆"
                            :active-value="1"
                            :inactive-value="0"
                        />
                    </el-col>

                    <el-col :span="20" class="info-area">
                        <div v-if="formData.vehicle.has == 1">
                            <el-row class="vehicle-marketPrice">
                                <el-col :span="8" prop="vehicle.marketPrice">
                                    <el-form-item label="裸车价：" prop="vehicle.nakedPrice">
                                        <el-input
                                            v-model="formData.vehicle.nakedPrice"
                                            class="vehicle-marketPrice-input"
                                            size="mini"
                                            placeholder="请输入"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">万</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="付款：" prop="vehicle.paymentType">
                                        <el-radio-group
                                            v-model="formData.vehicle.paymentType"
                                            size="mini"
                                        >
                                            <el-radio-button
                                                v-for="item in carPaymentType"
                                                :key="item.number"
                                                :value="item.number"
                                                :label="item.number"
                                            >{{ item.cnVal }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div
                            v-else
                            style="text-align:center;height:42px;line-height:42px;color:#ccc"
                        >暂 无 内 容</div>
                    </el-col>
                </el-row>

                <!-- 房产 -->
                <el-row>
                    <el-col :span="4" class="switch-area">
                        <el-switch
                            v-model="formData.house.has"
                            active-text="房产"
                            :active-value="1"
                            :inactive-value="0"
                        />
                    </el-col>

                    <el-col :span="20" class="info-area">
                        <div v-if="formData.house.has == 1">
                            <el-row class="house-form">
                                <el-col :span="8" prop="house.marketPrice">
                                    <el-form-item label="房价：" prop="house.marketPrice">
                                        <el-input
                                            v-model="formData.house.marketPrice"
                                            class="house-input"
                                            size="mini"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">万</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="付款：" prop="house.paymentType">
                                        <el-radio-group
                                            v-model="formData.house.paymentType"
                                            size="mini"
                                        >
                                            <el-radio-button
                                                v-for="item in carPaymentType"
                                                :key="item.number"
                                                :value="item.number"
                                                :label="item.number"
                                            >{{ item.cnVal }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="类型：" prop="house.type">
                                        <el-select
                                            v-model="formData.house.type"
                                            size="mini"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in houseType"
                                                :key="item.number"
                                                :label="item.cnVal"
                                                :value="item.number"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div
                            v-else
                            style="text-align:center;height:42px;line-height:42px;color:#ccc"
                        >暂 无 内 容</div>
                    </el-col>
                </el-row>

                <!-- 公积金 -->
                <el-row>
                    <el-col :span="4" class="switch-area">
                        <el-switch
                            v-model="formData.gongjijin.has"
                            active-text="公积金"
                            :active-value="1"
                            :inactive-value="0"
                        />
                    </el-col>

                    <el-col :span="20" class="info-area">
                        <div v-if="formData.gongjijin.has == 1">
                            <el-row class="gongjijin-form">
                                <el-col :span="8">
                                    <el-form-item label="月缴：" prop="gongjijin.monthlyFee">
                                        <el-input
                                            v-model="formData.gongjijin.monthlyFee"
                                            class="gongjijin-input"
                                            size="mini"
                                            placeholder="请输入"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="年限：" prop="gongjijin.payYears">
                                        <el-input
                                            v-model="formData.gongjijin.payYears"
                                            class="gongjijin-input"
                                            size="mini"
                                            placeholder="请输入"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">年</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="类型：" prop="gongjijin.type">
                                        <el-radio-group
                                            v-model="formData.gongjijin.type"
                                            size="mini"
                                        >
                                            <el-radio-button
                                                v-for="item in gongjijin"
                                                :key="item.number"
                                                v-model="formData.gongjijin.type"
                                                :value="item.number"
                                                :label="item.number"
                                            >{{ item.cnVal }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div
                            v-else
                            style="text-align:center;height:42px;line-height:42px;color:#ccc"
                        >暂 无 内 容</div>
                    </el-col>
                </el-row>

                <!-- 社保 -->
                <el-row>
                    <el-col :span="4" class="switch-area">
                        <el-switch
                            v-model="formData.shebao.has"
                            active-text="社保"
                            :active-value="1"
                            :inactive-value="0"
                        />
                    </el-col>

                    <el-col :span="20" class="info-area">
                        <div v-if="formData.shebao.has == 1">
                            <el-row class="shebao-form">
                                <el-col :span="8">
                                    <el-form-item
                                        label="年限："
                                        style="margin-bottom:0px"
                                        prop="shebao.payYears"
                                    >
                                        <el-input
                                            v-model="formData.shebao.payYears"
                                            class="shebao-input"
                                            size="mini"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">年</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item
                                        label="类型："
                                        style="margin-bottom:0px"
                                        prop="shebao.type"
                                    >
                                        <el-select
                                            v-model="formData.shebao.type"
                                            size="mini"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in shebao"
                                                :key="item.number"
                                                :label="item.cnVal"
                                                :value="item.number"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div
                            v-else
                            style="text-align:center;height:42px;line-height:42px;color:#ccc"
                        >暂 无 内 容</div>
                    </el-col>
                </el-row>

                <!-- 保单 -->
                <el-row style="border-bottom: 1px #ccc solid;">
                    <el-col :span="4" class="switch-area">
                        <el-switch
                            v-model="formData.baodan.has"
                            active-text="保单"
                            :active-value="1"
                            :inactive-value="0"
                        />
                    </el-col>

                    <el-col :span="20" class="info-area">
                        <div v-if="formData.baodan.has == 1">
                            <el-row class="baodan-form">
                                <el-col :span="8">
                                    <el-form-item label="年缴：" prop="baodan.annualFee">
                                        <el-input
                                            v-model="formData.baodan.annualFee"
                                            class="baodan-input"
                                            size="mini"
                                            placeholder="请输入"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="7">
                                    <el-form-item label="年限：" prop="baodan.payYears">
                                        <el-input
                                            v-model="formData.baodan.payYears"
                                            class="baodan-input"
                                            size="mini"
                                            placeholder="请输入"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">年</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div
                            v-else
                            style="text-align:center;height:42px;line-height:42px;color:#ccc"
                        >暂 无 内 容</div>
                    </el-col>
                </el-row>
            </div>

            <!-- 跟进内容 -->
            <div class="textarea">
                <el-form-item prop="followContent">
                    <el-input
                        v-model="formData.followContent"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 7 }"
                        placeholder="请输入本次跟进内容"
                        maxlength="128"
                        show-word-limit
                    />
                </el-form-item>
            </div>
        </el-form>

        <!-- 添加微信对话框 -->
        <el-dialog
            custom-class="addWxDialog"
            :visible.sync="isShowDialogVisible"
            title="添加微信"
            :modal="false"
        >
            <div class="addWxDialog-input">
                <el-input v-model="addWxGreetings" placeholder="请输入打招呼语" size="small " />
            </div>
            <div class="addWxDialog-btn">
                <el-button type="primary" @click="addWx">添加</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import areas from '@/assets/js/areaServices';
import { addClientWx } from '@/api/my_client';
import { getYMDHMS } from '@/assets/js/time.js';
export default {
    name: 'TurnsCallPageForm',
    props: {
        type: {
            type: String,
            default: () => 'clue',
        },
        pageType: {
            type: String,
            default: () => '0',
        },
        resData: {
            type: Object,
            default: () => null,
        },
        id: {
            type: [Number, String],
            default: () => 0,
        },
    },
    data() {
        return {
            formData: {
                expiredTime: '',
                createdTime: '',
                name: '',
                sex: '',
                age: '',
                residence: '', // 居住地
                fundNeeds: '', // 资金需求
                purposeLevel: '', // 意向度
                followContent: '', //  跟进内容
                vehicle: {
                    has: 0, //是否有车
                    nakedPrice: '', //裸车价
                    paymentType: '', //付款方式
                },
                house: {
                    has: 0, //是否有房
                    marketPrice: '', //房价
                    type: '', // 房产类型
                    paymentType: '', // 付款方式
                },
                gongjijin: {
                    has: 0, //是否有公积金
                    type: '', //单双边类型
                    payYears: '', //缴纳年限
                    monthlyFee: '', //月缴费用
                },
                shebao: {
                    has: 0, //是否有社保
                    type: '', //社保类型
                    payYears: '', //参保年限
                },
                baodan: {
                    has: 0, //是否有保单
                    annualFee: '', //年缴费用
                    payYears: '', //
                },
            },
            sexArr: [],
            purposeLevel: [], //意向
            areasOption: [], //地区
            carPaymentType: [], //车辆支付类型
            houseType: [], // 房子类型
            gongjijin: [], //公积金类型
            shebao: [], // //社保类型
            baodan: [], // 保单类型
            wechatServiceEnabled: 0, // 微信管理服务启用，0=禁用，1-启用
            isShowDialogVisible: false, // 添加微信对话框是否可见
            addWxGreetings: '', // 添加微信用户语
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 32,
                        message: '长度是1-32个字符之间',
                        trigger: 'blur',
                    },
                    {
                        validator: this.formValidation.name,
                        trigger: 'blur',
                    },
                ],
                age: [
                    {
                        validator: this.formValidation.ldq,
                        trigger: 'blur',
                    },
                ],
                fundNeeds: [
                    {
                        validator: this.formValidation.ldyw,
                        trigger: 'change',
                    },
                ],
                vehicle: {
                    nakedPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'change',
                        },
                    ],
                },
                house: {
                    marketPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'blur',
                        },
                    ],
                },
                gongjijin: {
                    monthlyFee: [
                        {
                            validator: this.formValidation.ldsw,
                            trigger: 'blur',
                        },
                    ],
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
                shebao: {
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
                baodan: {
                    annualFee: [
                        {
                            validator: this.formValidation.ldyy,
                            trigger: 'blur',
                        },
                    ],
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
            },
        };
    },
    methods: {
        // 数据处理
        getTreeData(data) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        // 添加微信弹出框
        openAddWxDialog() {
            this.addWxGreetings = '';
            this.isShowDialogVisible = true;
        },
        addWx() {
            this.isShowDialogVisible = false;

            addClientWx({
                mobileNumber: this.common.hidePhoneNumber(
                    this.formData.mobileNumber,
                    1
                ),
                message: this.addWxGreetings,
                clientId: this.id,
                clientName: this.formData.name,
            }).then((res) => {
                if (res.code !== '0') return this.$message.error(res.message);
                this.$message({
                    message: `添加成功，今天还可以添加${res.data.remainderCount}个微信`,
                    type: 'success',
                });
            });
        },
        // 同步信息
        syncInfo(type) {
            if (this.resData === null) return console.log('同步信息报错');
            const formData = this.formData;
            const resData = this.resData;
            if (type === 'info') {
                formData.name = resData.name;
                formData.sex = resData.sex;
                formData.age = resData.age;
                formData.residence = resData.residence;
            } else if (type === 'need') {
                formData.fundNeeds = resData.fundNeeds;
                formData.purposeLevel = resData.purposeLevel;
            }
        },
    },
    computed: {
        // 是否显示添加微信
        isShowWx() {
            if (this.type === 'client' && this.pageType === '0') {
                return true;
            }
            return false;
        },
        // 时间处理
        lastFollowedTime() {
            let time = '';
            try {
                time = getYMDHMS(this.resData.lastFollowedTime);
            } catch (error) {
                console.log(error);
            }
            return time;
        },
    },
    created() {
        this.carPaymentType = this.common.allValueNeed('vehicle-pay-type');
        this.purposeLevel = this.common.allValueNeed('loan-purpose-level');
        this.sexArr = this.common.allValueNeed('sex');
        this.houseType = this.common.allValueNeed('house-type');
        this.gongjijin = this.common.allValueNeed('gongjijin-pay-type');
        this.shebao = this.common.allValueNeed('shebao-type');
        this.baodan = this.common.allValueNeed('loan-purpose-level');
        this.wechatServiceEnabled = JSON.parse(
            window.sessionStorage.getItem('config')
        ).wechatServiceEnabled;
        this.areasOption = areas.getAll();
    },
};
</script>

<style lang="less" scoped>
.container {
    padding: 0px 20px;
    font-size: 14px;
    position: relative;
}
.tip {
    background-color: rgba(249, 249, 249, 1);
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
}

.title-font {
    background-color: rgba(249, 249, 249, 1);
    margin-top: 15px;
    padding: 10px;
    .base-info {
        margin-left: 5px;
        cursor: pointer;
    }
}

// 基本信息表单
.basic-info-form {
    height: 60px;
    padding: 10px 0px 10px 20px;
    .name-input {
        width: 70px;
    }

    .age-input {
        width: 90px;
        padding-top: 6px;
        /deep/.el-input-group__append {
            width: 30px;
            padding: 0 9px;
        }
        /deep/ .el-input__inner {
            padding: 0 10px;
        }
    }
    .residence-input {
        width: 100px;
    }
}

// 贷款需求表单
.loan-demand-form {
    height: 70px;
    padding: 10px 0px 10px 20px;
    .loan-demand-form-fundNeeds {
        width: 100px;
        padding-top: 6px;
        /deep/.el-input-group__append {
            width: 30px;
            padding: 0 9px;
        }
    }
}

.qualification-form {
    padding-top: 20px;

    .switch-area {
        height: 42px !important;
        line-height: 42px;
        padding-left: 20px;
        border: 1px solid #ccc;
        border-bottom: none;
    }

    .info-area {
        border: 1px solid #ccc;
        border-left: 0;
        border-bottom: none;
        line-height: 42px;
        height: 42px !important;
    }

    // 车
    .vehicle-marketPrice {
        padding-left: 10px;

        /deep/ .vehicle-marketPrice-input {
            width: 120px;
            padding-top: 6px;

            .el-input-group__append {
                padding: 0 10px;
            }
        }
    }

    // 房产
    .house-form {
        padding-left: 10px;

        .house-input {
            width: 120px;
            padding-top: 6px;

            .el-input-group__append {
                padding: 0 10px;
            }
        }

        /deep/ .el-select {
            width: 120px;
        }
    }

    // 公积金
    .gongjijin-form {
        padding-left: 10px;

        .gongjijin-input {
            width: 120px;
            padding-top: 6px;

            .el-input-group__append {
                padding: 0 10px;
            }
        }
    }

    // 社保
    .shebao-form {
        padding-left: 10px;

        .shebao-input {
            width: 120px;
            padding-top: 6px;

            .el-input-group__append {
                padding: 0 10px;
            }
        }

        /deep/ .el-select {
            width: 120px;
        }
    }

    // 保单
    .baodan-form {
        padding-left: 10px;

        .baodan-input {
            width: 120px;
            padding-top: 6px;

            .el-input-group__append {
                padding: 0 10px;
            }
        }
    }
}

// 跟进内容
.textarea {
    position: absolute;
    top: 0px;
    right: -315px;
    /deep/ .el-textarea__inner {
        width: 300px;
        height: 135px !important;
    }
}

// 添加微信
.addWxBtn:hover {
    cursor: pointer;
}

// 添加微信对话框
/deep/ .addWxDialog {
    top: 190px;
    left: 66%;
    width: 360px;
    .el-dialog__header {
        padding: 20px 20px 15px;
        text-align: center;
    }
    .el-dialog__body {
        display: flex;
        padding: 0px 24px 20px;
        .addWxDialog-input {
            flex: 100;
            padding-right: 5px;
        }
        .addWxDialog-btn {
            flex: 20;
            padding-left: 5px;
        }
    }
}
</style>